import React, { useEffect, useState } from 'react'
import { navigate, PageProps } from 'gatsby'
import { AutoComplete, Head } from '~/components'
import * as st from '~/assets/styl/Home.module.styl'
import logoUnicred from '~/assets/svg/svg-logo-black.svg'
import logoJuntos from '~/assets/svg/juntos-transformamos.png'
// import logoUnidos from '~/assets/svg/svg-unidos-black.svg'
import { Link } from '~/components'
import cn from 'classnames'
import { useContext } from '~/hooks'
import Cooperativa from './Cooperativa'
import BeneficiosLista from './BeneficiosLista'
import BeneficioDetalhe from './BeneficioDetalhe'

const Home = (props: PageProps & { '*'?: string }) => {
  const { location, '*': slug } = props

  if (slug) {
    const slugParts = slug.split('/')
    if (slugParts.length === 1) {
      return <Cooperativa {...props} />
    } else if (slugParts.length === 2) {
      return <BeneficiosLista {...props} />
    } else if (slugParts.length === 3) {
      return <BeneficioDetalhe {...props} />
    } else {
      useEffect(() => {
        navigate('/404')
      }, [])
      return <></>
    }
  }

  const { setLoading } = useContext()
  useEffect(() => {
    setLoading(false)
  }, [])

  const [visible, setVisible] = useState(false)
  const [titleBtn, setTitleBtn] = useState('Encontre sua cooperativa')

  return (
    <>
      <Head location={location} />
      <main className={st.core}>
        <img src={logoUnicred} alt="Logo da Unicred" className={st.logoTop} />
        <section>
          <h1 className="title large-size">Benefícios para você</h1>
          <p>
            Neste hotsite, você vai acessar de forma rápida e fácil o portfólio
            de benefícios ofertados para os colaboradores da Unicred Regional
            Conexão. Clique abaixo, escolha a sua Cooperativa e saiba mais!
          </p>
          <div className={st.containerPrincipal}>
            <div className={st.containerMenuNav}>
              <div>
                <button
                  className={cn(st.btnOpenInput, visible && st.active)}
                  onClick={() => setVisible(!visible)}
                >
                  {titleBtn}
                </button>
              </div>
              <div className={cn(st.containerSearch, visible && st.active)}>
                <AutoComplete
                  className={st.autocomplete}
                  // placeholder="Qual é a sua Unidade Regional?"
                  setTitleBtn={setTitleBtn}
                  setVisible={setVisible}
                />
              </div>
            </div>
          </div>
          <Link
            href=""
            className={cn('btn color-white bkg-green', st.btnAcess)}
          >
            Acessar benefícios
          </Link>
        </section>
        <div className={st.containerReasons}>
          <ul className={st.reasonsList}>
            <li>
              <span>Para o seu desenvolvimento profissional</span>
            </li>
            <li>
              <span>Para a sua saúde</span>
            </li>
            <li>
              <span>Para o seu bem-estar financeiro</span>
            </li>
            <li>
              <span>Para o seu bem-estar familiar e social</span>
            </li>
            <li>
              <span>Para o seu reconhecimento</span>
            </li>
            <li>
              <img src={logoJuntos} alt="Logo da Juntos Transformamos" />
            </li>
          </ul>
        </div>

        <img
          src={logoJuntos}
          alt="Logo da Juntos Transformamos"
          className={st.logoJuntos}
        />

        <a
          href="https://www.tiki.com.br/"
          title="Site da Tiki Web"
          target="_blank"
          rel="noreferrer"
        >
          Tiki
        </a>
      </main>
    </>
  )
}

export default Home
